<template>
	<!-- <div class="background"></div> -->
	<div class="cssload-container">
		<div class="cssload-arc">
			<div class="cssload-arc-cube"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SpinnerJump',
		props: {}, // props
		components: {},
		data() {
			return {}
		},
	}
</script>

<style lang="less" scoped>
	.cssload-container {
		position: relative;
		width: 195px;
		height: 195px;
		display: block;
		margin: 49px auto;
		perspective: 975px;
		-o-perspective: 975px;
		-ms-perspective: 975px;
		-webkit-perspective: 975px;
		-moz-perspective: 975px;
		perspective: 975px;
		-o-perspective: 975px;
		-ms-perspective: 975px;
		-webkit-perspective: 975px;
		-moz-perspective: 975px;
		overflow: hidden;
	}

	.cube {
		position: absolute;
		bottom: 50%;
		left: 50%;
		width: 29px;
		height: 29px;
		margin-left: -15px;
		margin-top: 0;
	}

	.cube-2d {
		background-color: rgb(0, 0, 0);
	}
	.cssload-arc {
		position: absolute;
		bottom: 50%;
		left: 50%;
		margin-left: -68px;
		width: 136px;
		height: 68px;
		overflow: hidden;
	}

	.cssload-arc-cube {
		position: absolute;
		bottom: -68px;
		left: 50%;
		margin-left: -68px;
		width: 136px;
		height: 136px;
		border-style: solid;
		border-top-color: transparent;
		border-right-color: red;
		border-left-color: transparent;
		border-bottom-color: transparent;
		border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		animation: cssload-arc 2.3s ease-in-out infinite;
		-o-animation: cssload-arc 2.3s ease-in-out infinite;
		-ms-animation: cssload-arc 2.3s ease-in-out infinite;
		-webkit-animation: cssload-arc 2.3s ease-in-out infinite;
		-moz-animation: cssload-arc 2.3s ease-in-out infinite;
		transform: rotate(-200deg);
		-o-transform: rotate(-200deg);
		-ms-transform: rotate(-200deg);
		-webkit-transform: rotate(-200deg);
		-moz-transform: rotate(-200deg);
	}

	@keyframes cssload-arc {
		0% {
			border-width: 29px;
		}
		25% {
			border-width: 15px;
		}
		50% {
			transform: rotate(27deg);
			border-width: 29px;
		}
		75% {
			border-width: 15px;
		}
		100% {
			border-width: 29px;
		}
	}

	@-o-keyframes cssload-arc {
		0% {
			border-width: 29px;
		}
		25% {
			border-width: 15px;
		}
		50% {
			-o-transform: rotate(27deg);
			border-width: 29px;
		}
		75% {
			border-width: 15px;
		}
		100% {
			border-width: 29px;
		}
	}

	@-ms-keyframes cssload-arc {
		0% {
			border-width: 29px;
		}
		25% {
			border-width: 15px;
		}
		50% {
			-ms-transform: rotate(27deg);
			border-width: 29px;
		}
		75% {
			border-width: 15px;
		}
		100% {
			border-width: 29px;
		}
	}

	@-webkit-keyframes cssload-arc {
		0% {
			border-width: 29px;
		}
		25% {
			border-width: 15px;
		}
		50% {
			-webkit-transform: rotate(27deg);
			border-width: 29px;
		}
		75% {
			border-width: 15px;
		}
		100% {
			border-width: 29px;
		}
	}

	@-moz-keyframes cssload-arc {
		0% {
			border-width: 29px;
		}
		25% {
			border-width: 15px;
		}
		50% {
			-moz-transform: rotate(27deg);
			border-width: 29px;
		}
		75% {
			border-width: 15px;
		}
		100% {
			border-width: 29px;
		}
	}
</style>
