<template>
    <button class="button is-danger is-small" @click="handleLogout" v-if="isAuthenticated"> Log Out : {{user.nickname}} </button>
    <button class="button is-success is-small" @click="handleLogin" v-else> Log In </button>
  </template>
  
  <script setup>
  import { useAuth0 } from "@auth0/auth0-vue";
  
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  
  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        target: "/",
      },
    });
  };

  const handleLogout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  </script>