import { api2 } from './store/index.js';
import dayjs from "dayjs"
require('dayjs/locale/en-gb');
dayjs.locale('en-gb');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/London');

class dated_field {
    constructor(o) {
        if (o) {
            //console.log(o)
            Object.assign(this, o)
            if (this.datetime) this.datetime = dayjs(this.datetime)
        } else {
            this.datetime = dayjs(null)
        }
    }
    get days() { return this.datetime.isValid() ? dayjs().diff(this.datetime, 'days') : null }
    get age_string() {
        if (this.days == null || isNaN(this.days)) return '-'
        if (this.days == 0) return 'TODAY'
        if (this.days == 1) return 'YESTERDAY'
        return `${this.days}d ago`
    } // age_string
}

function round2(gbp) { return Math.round(gbp * 100) / 100.0 }

class Inventory {

    constructor(o) {
        try {
            if (o) { Object.assign(this, o) }
            this.estimated_stock = 1
            this.minimum_stock = 2
            this.stock_days = 3
            //this.history = null
            this.name_lowercase = this.name.toLowerCase()
            this.start_date = null

            //this.is_history_fetched = false
        } catch (e) {
            console.log(e)
        }
    } // constructor

    get last_purchase() { return (!this.purchases || this.purchases.length == 0) ? null : this.purchases[0] }
    get last_order() { return (!this.orders || this.orders.length == 0) ? null : this.orders[0] }
    get last_check() { return (!this.checks || this.checks.length == 0) ? null : this.checks[0] }
    get last_check_request() { return (!this.check_requests || this.check_requests.length == 0) ? null : this.check_requests[0] }

    get is_ordered() {
        if (!this.last_order) return false
        if (this.last_purchase == null) return true
        return this.last_purchase.datetime.isBefore(this.last_order.datetime)
    } // is_ordered

    get is_check_requested() {
        if (this.last_check_request == null) return false
        if (this.last_check == null) return true
        return this.last_check.datetime.isBefore(this.last_check_request.datetime)
    } // is_check_requested

    get is_low_stock() { return false }
    get is_old_check() { return this.last_check == null || this.last_check.datetime.days > 15 }

    async check(quantity, datetime, unit) {
        if (quantity == null) return
        let d = {
            ingredient_id: this.ingredient_id,
            quantity: quantity,
            datetime: datetime,
            unit: unit
        }
        var r = await api2.post('/api/v2/inventory/check', d);
        this.checks.unshift(new dated_field(r))
    }

    async purchase(quantity, price, datetime, invoice) {
        if (quantity == null) return
        let d = {
            ingredient_id: this.ingredient_id,
            quantity: quantity,
            price: price,
            datetime: datetime,
            invoice: invoice
        }
        var r = await api2.post('/api/v2/inventory/purchase', d);
        this.purchases.unshift(new dated_field(r))
    }

    async request_check(status) {
        try {
            let d = {
                ingredient_id: this.ingredient_id,
                department: null
            }
            console.log(d)
            if (status)
                var r = await api2.post('/api/v2/inventory/check/request', d);
            else
                var r = await api2.delete('/api/v2/inventory/check/request', d);
            this.check_requests.unshift(new dated_field(r))
        } catch (e) {
            console.log(e)
        }
    }
    async order(status) {
        try {
            //this.last_order.datetime = dayjs()
            let d = {
                ingredient_id: this.ingredient_id
            }
            // console.log(d)
            if (status)
                var r = await api2.post('/api/v2/inventory/order', d);
            else
                var r = await api2.delete('/api/v2/inventory/order', d);
            this.orders.unshift(new dated_field(r))
        } catch (e) {
            console.log(e)
        }
    } // order

    async fetch_purchases(start_date) {
        try {
            if (this.purchases) return
            console.log(`purchases ${this.ingredient_id} since ${start_date}`)
            this.purchases = await api2.get(`/api/v2/inventory/purchases/${this.ingredient_id}/${start_date}`)
            //console.log(this.history)
            if (this.purchases) {
                for (let h of this.purchases) {
                    h.datetime = dayjs(h.datetime)
                    h.purchase_unit_price = h.quantity = 0 ? 0 : h.price / h.quantity
                }
            }
            console.log(this.purchases)
            //this.is_history_fetched = true
        } catch (e) {
            console.log(e)
        }
    } // fetch_purchases    

    async fetch_orders(start_date) {
        try {
            if (this.orders) return
            console.log(`orders ${this.ingredient_id} since ${start_date}`)
            this.orders = await api2.get(`/api/v2/inventory/orders/${this.ingredient_id}/${start_date}`)
            console.log(this.orders)
        } catch (e) {
            console.log(e)
        }
    } // fetch_orders    

    async fetch_checks(start_date) {
        try {
            if (this.checks) return
            console.log(`load_checks ${this.ingredient_id} since ${start_date}`)
            this.purchases = await api2.get(`/api/v2/inventory/checks/${this.ingredient_id}/${start_date}`)
            console.log(this.checks)
            //this.is_history_fetched = true
        } catch (e) {
            console.log(e)
        }
    } // fetch_checks

    static async fetch_all() {
        try {
            //console.log('Inventory.fetch_all')
            var date = dayjs().subtract(6, 'month');
            let [ingredients, purchases, orders, checks, check_requests] = await Promise.all([
                api2.get('/api/v2/inventory'),
                api2.get(`/api/v2/inventory/purchases/all/${date.format('YYYY-MM-DD')}`),
                api2.get(`/api/v2/inventory/orders/all/${date.format('YYYY-MM-DD')}`),
                api2.get(`/api/v2/inventory/checks/all/${date.format('YYYY-MM-DD')}`),
                api2.get(`/api/v2/inventory/check/requests/all/${date.format('YYYY-MM-DD')}`)
            ]);

            check_requests = check_requests.map(x => new dated_field(x))
            checks = checks.map(x => new dated_field(x))
            orders = orders.map(x => new dated_field(x))
            purchases = purchases.map(x => new dated_field(x))
            ingredients = ingredients.map(x => new Inventory(x))

            for (let x of checks) x.type = 'check'
            for (let x of orders) x.type = 'order'
            for (let x of check_requests) x.type = 'check_request'
            for (let x of purchases) x.type = 'purchase'

            for (let i of ingredients) {
                i.start_date = date
                i.purchases = purchases.filter(x => x.ingredient_id == i.ingredient_id)
                i.orders = orders.filter(x => x.ingredient_id == i.ingredient_id)
                i.checks = checks.filter(x => x.ingredient_id == i.ingredient_id)
                i.check_requests = check_requests.filter(x => x.ingredient_id == i.ingredient_id)
            }
            //console.log(ingredients)
            return ingredients
        } catch (e) {
            console.log(e)
        }
    } // fetch_all
}

export { Inventory }