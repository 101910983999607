import { reactive } from 'vue';
import { Inventory } from '../dao';

const api2 = {
	// headers: {
	// 	Accept: 'application/json',
	// 	'Content-Type': 'application/json',
	// 	mode: 'same-origin',
	// 	redirect: 'follow',
	// 	credentials: 'include',
	// },
	set_token(token) { sessionStorage.setItem("token", token) },
	handleErrors(response) {
		console.log(JSON.stringify(response));
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	},
	get(url) {
		return this.method('get', url);
	},
	post(url, d) {
		return this.method('post', url, d);
	},
	put(url, d) {
		return this.method('put', url, d);
	},
	delete(url, d) {
		return this.method('delete', url, d);
	},
	method(verb, url, data) {
		let token = sessionStorage.getItem("token");
		let options = {
			credentials: 'include',
			method: verb,
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data),
		}
		//console.log(`options : ${JSON.stringify(options)}`)
		return fetch(url, options)
			.then(response => {
				//console.log(response);
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then(j => j);
	},
};

var stock = reactive({
	is_loaded: false,
	items: [],
	async fetch_all() {
		console.log('stock.fetch_all')
		if (this.is_loaded) return
		this.items = await Inventory.fetch_all();
		console.log(`Loaded ${this.items.length} ingredients`)
		this.is_loaded = true
	}, // fetch_all
})

var store = reactive({
	_loading: 0,
	set loading(v) {
		if (v) this._loading++;
		else this._loading--;
	},
	get loading() {
		return this._loading > 0;
	},
	user: {
		current: null,
		logged_in: null,
		get name() {
			console.log('name');
			console.log();
			var n = this.current.name;
			if (this.current.name != this.logged_in.name) n += ` ( ${this.logged_in.name} )`;
			return n;
		},
		has_permission(p) {
			if (!this.logged_in || !this.logged_in.permissions)
				return false
			return this.logged_in.permissions.includes(p)
		},
	}, // user
	employees: null,
	skills: null,
	fetch_users: async function (email) {
		if (this.employees == null) {
			this.loading = true;
			console.log('fetch skills');
			this.skills = await api2.get(`/api/skills`);
			console.log(this.skills);

			console.log('fetch_users');
			this.employees = await api2.get(`/api/employees`);
			console.log(this.employees);
			for (let u of this.employees) {
				//u.skills = this.skills.filter(x => u.skills.includes(x.skill_id));
				u.skills = u.skills.map(x => this.skills.find(y => y.skill_id == x));
				u.is_admin = u.role == 'admin' ? true : false;
			}
			console.log(this.user.current)

			// console.log('getting user');
			// var user = await api2.get('/api/user/profile');
			// console.log(user);
			// let u = this.employees.find((x) => x.email == metadata.employee_id);

			let u = this.employees.filter(x => x.email).find(x => x.email.toLowerCase() == email.toLowerCase());
			u.has_permission = function(p){ return this.permissions.includes(p)}

			console.log(u);
			this.user.logged_in = u;
			this.user.current = u;

			this.loading = false;
		}
	}, // fetch_users
}); // store

var page = reactive({
	name: '',
	parent: null,
	_loading: 0,
	is_success: false,
	is_failure: false,
	text: '',
	get is_loading() {
		return this._loading > 0;
	},

	success(s) {
		//console.log('success')
		this.text = s;
		this.is_success = true;
		setTimeout(() => {
			this.is_success = false;
			this.text = '';
		}, 1200);
	},
	failure(s) {
		this.is_failure = true;
		this.text = s;
		setTimeout(() => {
			this.is_failure = false;
			this.text = '';
		}, 3600);
	},
	load(v) {
		this._loading += v ? 1 : -1;
	}, // load
});

export { api2, store, page, stock };
