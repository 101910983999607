import { createApp, reactive } from 'vue';
import App from './App.vue';

//import "./styles/app.css"; // Here

import dayjs from 'dayjs';
require('dayjs/locale/en-gb');
dayjs.locale('en-gb');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/London');

import VueSweetalert2 from 'vue-sweetalert2';
import '@sweetalert2/theme-dark/dark.css';

import Vue3TouchEvents from 'vue3-touch-events';
import { store, page, api2, stock } from './store/index.js';

import './registerServiceWorker'

const app = createApp(App)

//app.set('trust proxy', '127.0.0.1');
app.use(
    createAuth0({
        domain: "vpl.eu.auth0.com",
        clientId: "eRSOk1F3YF6BPZ890JeIVjwPSSOIA5RY",
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience:"data.vapensierolounge.com"
        }
    })
)

import router from './router.js';
import { createAuth0, createAuthGuard } from "@auth0/auth0-vue";
app.use(router(createAuthGuard(app))).use(VueSweetalert2).use(Vue3TouchEvents, { swipeTolerance: 50 });

import { createPinia } from 'pinia';
const pinia = createPinia()
app.use(pinia)

app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$store = store;
app.config.globalProperties.$api2 = api2;
app.config.globalProperties.$page = page;
app.config.globalProperties.$stock = stock;


Number.prototype.toStringGbp = function () { return isNaN(this) ? this : (this / 100).toFixed(2); }

app.mount('#app');
