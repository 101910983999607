import { createRouter, createWebHistory  } from "vue-router";
//import { createAuthGuard } from "@auth0/auth0-vue";

const router = function (authGuard) {
	const routes = [
		{
			path: '/',
			name: 'Home',
			beforeEnter: authGuard,
			component: () => import(/* webpackChunkName: "main" */ './views/Home.vue'),
		},
		{
			path: '/shifts/:week?',
			name: 'Shifts',
			beforeEnter: authGuard,
			//beforeEnter: checkAuth,
			component: () => import(/* webpackChunkName: "shifts" */ './views/calendar/shifts.vue'),
			children: [], // calendar - children
		},
		{
			path: '/shifts2/:week?',
			name: 'Shifts2',
			beforeEnter: authGuard,
			component: () => import(/* webpackChunkName: "shifts" */ './views/calendar2/shifts.vue'),
			children: [], // calendar - children
		},
		{
			path: '/shift/add/:date',
			name: 'Shift.Add',
			beforeEnter: authGuard,
			component: () => import(/* webpackChunkName: "shifts.admin" */ './views/calendar/shifts.add.vue'),
		}, // shift.add
		{
			path: '/shift/:shift_id/messages',
			name: 'Shift.Messages',
			beforeEnter: authGuard,
			//props: true,
			component: function () {
				return import(/* webpackChunkName: "shifts.admin" */ './views/calendar/messages.vue');
			},
		},
		{
			path: '/shift/:shift_id',
			name: 'Shift',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "shifts" */ './views/calendar/shift.vue');
			},
		}, // shift
		{
			path: '/training',
			name: 'Training',
			//beforeEnter: checkAuth,
			component: function () {
				return import(/* webpackChunkName: "training" */ './views/training/training.vue');
			},
		}, // inventory
		{
			path: '/inventory',
			name: 'Inventory2',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "inventory" */ './views/inventory/inventory2.vue');
			},
		}, // inventory
		{
			path: '/internal-production',
			name: 'internal-production',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "inventory" */ './views/inventory/internal-production.vue');
			},
		}, // inventory
		{
			path: '/inventory/add/:ingredient_id/:type?',
			props: true,
			name: 'Inventory-add',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "inventory" */ './views/inventory/inventory-add.vue');
			},
		}, // inventory/add
		{
			path: '/inventory/request',
			//props:true,
			name: 'inventory-request',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "inventory" */ './views/inventory/inventory-request.vue');
			},
		}, // inventory/request
		{
			path: '/inventory/detail/:ingredient_id',
			props: true,
			name: 'inventory-product',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "inventory" */ './views/inventory/inventory-detail.vue');
			},
		}, // products
		{
			path: '/inventory/products/:id?',
			name: 'Product',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "inventory" */ './views/inventory/products.vue');
			},
		}, // products
		{
			path: '/inventory/edit/:ingredient_id',
			name: 'Inventory-Edit',
			props: true,
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "inventory" */ './views/inventory/edit.vue');
			},
		}, // edit
		{
			path: '/location',
			name: 'Location',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "main" */ './views/location.vue');
			},
		}, // inventory
		{
			path: '/procedures/closing',
			name: 'Closing',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "procedures" */ './views/procedures/closing.vue');
			},
		}, // closing
		{
			path: '/banking/till',
			name: 'Till',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "banking" */ './views/banking/till2.vue');
			},
		}, // tlll
		{
			path: '/banking/receipts',
			name: 'Receipts',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "banking" */ './views/banking/receipts.vue');
			},
		}, // tlll
		{
			path: '/banking/end-of-day',
			name: 'EndOfDay',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "banking" */ './views/banking/end-of-day.vue');
			},
		}, // tlll
		{
			path: '/banking/card_totals',
			name: 'Card_Totals',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "till" */ './views/banking/card-totals.vue');
			},
		}, // tlll
		{
			path: '/temperature',
			name: 'Temperature',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "main" */ './views/various/temperature.vue');
			},
		}, // temperature
		{
			path: '/utilities',
			name: 'Utilities',
			beforeEnter: authGuard,
			component: function () {
				return import(/* webpackChunkName: "main" */ './views/various/utilities.vue');
			},
		}, // temperature
		{
			path: '/not-authorised',
			name: 'NotAuthorised',
			component: function () {
				return import(/* webpackChunkName: "main" */ './views/NotAuthorised.vue');
			},
		}, // NotAuthorised
		// {
		// 	path: '/callback',
		// 	name: 'Callback',
		// 	component: function () {
		// 		return import(/* webpackChunkName: "main" */ './views/Callback.vue');
		// 	},
		// }, // NotAuthorised	
	]

	return createRouter({
		history: createWebHistory(process.env.BASE_URL),
		routes,
	})

}

//import { store } from '../store/index.js';

// function checkAuth(to, from, next) {
// 	console.log('checkAuth');
// 	console.log(store.user);
// 	// if (store.user.logged_in) return next();
// 	// return next('/not-authorised');
// }


export default router;
