<template>
	<!-- <div
    class="navbar mb-2 shadow-lg bg-neutral text-neutral-content rounded-box"
  >
    <div class="flex-none">
      <button class="btn btn-square btn-ghost">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-6 h-6 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </button>
    </div>
    <div class="flex-1 px-2 mx-2">
      <a class="text-lg font-bold" href="/"> Va Pensiero </a>
    </div>
    <div class="flex-none">
      <button class="btn btn-square btn-ghost">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="inline-block w-6 h-6 stroke-current"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
          ></path>
        </svg>
      </button>
    </div>
  </div> -->

	<div v-if="user.logged_in">
		<nav class="navbar" role="navigation" aria-label="main navigation">

			<div class="is-flex is-align-items-center is-flex-direction-row" v-on:click="$router.push('/')">
				<i class="fa-solid fa-house-user fa-2xl m-2"></i>
				<span class="">HOME</span>
			</div>

			<div style="width:150px">
				<figure class="image is-3by1">
					<img src="../assets/va_pensiero_logo_pink.svg" alt="Va Pensiero" />
				</figure>
			</div>

			<div class=" is-flex is-align-items-center is-flex-direction-row is-clickable m-3">
				<span><i class="fas fa-bars fa-2xl" v-on:click="open = true"></i></span>
			</div>
		</nav>

		<div class="overlay" :class="{ isOpen: open }">
			<a href="javascript:void(0)" class="closebtn" v-on:click="open = false">&times;</a>
			<div class="overlay-content">
				<a v-if="user.logged_in.is_admin" href="#" v-for="e in employees" v-on:click="close_dropdown(e)" :class="{
					admin: e.is_admin,
					current: e.employee_id == user.current.employee_id,
				}">
					{{ e.calendar_id }}
				</a>
				<hr />

				<LoginButton />
			</div>
		</div>
	</div>
</template>

<script>
import LoginButton from "@/components/login-button.vue";

export default {
	name: 'Navbar',
	components: { LoginButton },
	data: function () {
		return {
			open: false,
		};
	},
	mounted: function () {
		//console.log(this.user);
	},
	methods: {
		close_dropdown(e) {
			var top = window.pageYOffset || document.documentElement.scrollTop;
			//console.log(`top : ${top}`);
			this.user.current = e;
			window.scrollTo(0, top);
			setTimeout(() => { this.open = false; }, 100);
		},
	}, // methods
	computed: {
		user() { return this.$store.user; },
		employees() { return this.$store.employees; },
	}, // computed
};
</script>
<style lang="less">
.navbar {
	display: flex;
	align-content: center;
	justify-content: space-between;
	color: #e0c9bc;

	a {
		color: #e0c9bc;
	}

}

/* ---------------------------------------------------------------------------------------------------------------------- */
/* The Overlay (background) */
.overlay {
	/* Height & width depends on how you want to reveal the overlay (see JS below) */
	height: 100%;
	width: 0;
	position: fixed;
	/* Stay in place */
	z-index: 2000;
	/* Sit on top */
	left: 0;
	top: 0;
	background-color: rgb(0, 0, 0);
	/* Black fallback color */
	background-color: rgba(0, 0, 0, 0.9);
	/* Black w/opacity */
	overflow-x: hidden;
	/* Disable horizontal scroll */
	transition: 0.5s;
	/* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

	&.isOpen {
		width: 100%;
	}
}

/* Position the content inside the overlay */
.overlay-content {
	position: relative;
	top: 5%;
	/* 25% from the top */
	width: 100%;
	/* 100% width */
	text-align: center;
	/* Centered text/links */
	margin-top: 25px;
	/* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
	padding: 8px;
	text-decoration: none;
	font-size: 1.5em;
	color: #818181;
	display: block;
	/* Display block instead of inline */
	transition: 0.3s;
	/* Transition effects on hover (color) */
}

.overlay a.admin {
	color: yellow;
}

.overlay a.current {
	color: red;
}

.overlay a.logout {
	color: blue;
	//border-top: 1px solid white;
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover,
.overlay a:focus {
	color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
	position: absolute;
	right: 0.5em;
	font-size: 3em;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
	.overlay a {
		font-size: 20px;
	}

	.overlay .closebtn {
		font-size: 40px;
		top: 15px;
		right: 35px;
	}
}

/* -------------------------------------------------------------------------- */
.floating-button {
	box-shadow: 0 6px 10px 0 #666;
	transition: all 0.1s ease-in-out;

	font-size: 25px;
	color: white;
	text-align: center;
	line-height: 45px;

	position: fixed;
	z-index: 1000;
}

.floating-button:hover {
	box-shadow: 0 6px 14px 0 #666;
	transform: scale(1.05);
}

.floating-button.users {
	width: 45px;
	height: 45px;
	background-color: red;
	border-radius: 50%;
	right: 5px;
	top: 7.5px;
}

.floating-button.today {
	width: 45px;
	height: 45px;
	background-color: red;
	border-radius: 50%;
	left: 5px;
	bottom: 7.5px;
}
</style>
