<template>
	<NavBar></NavBar>
	<!-- <loading :active="$store.loading" v-bind:can-cancel="false" is-full-page background-color="black" color="red" /> -->
	<!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->

	<router-view v-if="$store.user.logged_in" />
	<!-- <router-view v-if="$store.user.logged_in" /> -->

	<div v-else>
		<h1>Oops, it seems i don't know your email address.</h1>
		<h2>Please login again with your registered email</h2>
		<!-- <div>{{ isAuthenticated }}</div>
		<div> {{ user }}</div> -->

		<LoginButton />

	</div>

	<div class="result-box load bounce" v-if="$page.is_loading">
		<!-- <div class="lds-heart"><div></div></div> -->
		<SpinnerJump></SpinnerJump>
	</div>

	<!-- <SpinnerJump v-if="$page.is_loading"></SpinnerJump> -->

	<div class="result-box success bounce" v-if="$page.is_success">
		<h1 class="has-text-black is-size-3">{{ $page.text }}</h1>
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
			<circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1"
				cy="65.1" r="62.1" />
			<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round"
				stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
		</svg>
	</div>
	<div class="result-box failure bounce" v-if="$page.is_failure">
		<h1 class="has-text-black is-size-3">{{ $page.text }}</h1>
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
			<circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1"
				cy="65.1" r="62.1" />
			<line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
				stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
			<line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
				stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
		</svg>
	</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
//import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import SpinnerJump from '@/components/SpinnerJump.vue';
import LoginButton from "@/components/login-button.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { onMounted, toValue, watch, getCurrentInstance } from 'vue';
import { api2 } from '@/store'
//import useUserStore from '@/store/user.js'


export default {
	name: "",
	components: { NavBar, SpinnerJump, LoginButton },
	setup() {
		const auth0 = useAuth0()
		const app = getCurrentInstance()

		onMounted(() => { })

		const { getAccessTokenSilently } = useAuth0();

		watch(auth0.user, async (current, previous) => {
			if (previous == current) return
			try {
				const token = await getAccessTokenSilently();
				api2.set_token(token)
				const user = toValue(current)
				const store = app.appContext.config.globalProperties.$store

				//console.log(user['http://vapensierolounge.com/metadata'])
				await Promise.all([store.fetch_users(user.email)]);
			} catch (e) { console.log(e) }
		})

		return {
			isAuthenticated: auth0.isAuthenticated,
			isLoading: auth0.isLoading,
			user: auth0.user
		}
	}
};
</script>

<style lang="less">
:root {}

.floating-button {
	position: fixed;
	z-index: 10;

	&.left {
		bottom: 10px;
		left: 10px;
	}

	&.right {
		bottom: 10px;
		right: 10px;
	}
}

.is-divider {
	display: flex;
	align-items: center;
	font-size: 1em;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	--text-divider-gap: 1rem;

	&::before,
	&::after {
		content: '';
		height: 1px;
		background-color: silver;
		flex-grow: 1;
		color: red;
	}

	&::before {
		margin-right: 1em;
	}

	&::after {
		margin-left: 1em;
	}
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	//color: #2c3e50;
	max-width: 480px;
	margin-left: auto;
	margin-right: auto;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}



// .icon {
// 	&.is-action {
// 		cursor: pointer;
// 		pointer-events: initial !important;
// 	}
// }

//  CHECKMARK
// https://csshint.com/animated-check-mark-cross-icon/
.result-box {
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	padding: 3em;
	opacity: 0.9;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.success {
		background-color: green;
	}

	&.failure {
		background-color: red;
	}

	&.load {
		background-color: black;
	}

	svg {
		width: 100px;
		display: block;
		//margin: 40px auto 0;
	}

	.path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 0;

		&.circle {
			-webkit-animation: dash 0.9s ease-in-out;
			animation: dash 0.9s ease-in-out;
		}

		&.line {
			stroke-dashoffset: 1000;
			-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
			animation: dash 0.9s 0.35s ease-in-out forwards;
		}

		&.check {
			stroke-dashoffset: -100;
			-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
			animation: dash-check 0.9s 0.35s ease-in-out forwards;
		}
	}

	// .bounce {
	// 	animation: bounce 1s 0.5s;
	// 	transform: scale(0.85);
	// }

	// @keyframes bounce {
	// 	0% {
	// 		transform: scale(1.1);
	// 		opacity: 1;
	// 	}

	// 	50% {
	// 		transform: scale(1.6);
	// 		opacity: 0.7;
	// 	}

	// 	60% {
	// 		transform: scale(0.6);
	// 		opacity: 1;
	// 	}

	// 	80% {
	// 		transform: scale(0.95);
	// 	}

	// 	100% {
	// 		transform: scale(0.85);
	// 	}
	// }



	@-webkit-keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}

		100% {
			stroke-dashoffset: 0;
		}
	}

	@keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}

		100% {
			stroke-dashoffset: 0;
		}
	}

	@-webkit-keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}

		100% {
			stroke-dashoffset: 900;
		}
	}

	@keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}

		100% {
			stroke-dashoffset: 900;
		}
	}
}

// ----------------------------------------------------------------------
// -------------  CHECKBOXES  -------------------------------------------
// ----------------------------------------------------------------------

@black: #363839;
@lightgray: #9c9e9f;
@gray: #bdc1c6;
@white: #fff;
@green: #06842c;

.checkbox.large {
	font-size: 1.5em;
	line-height: 2em;

	//	input[type='checkbox'] {
	// transform: scale(1.5);
	// margin-right: 0.4em;
	//}
	input[type='checkbox'] {
		position: relative;
		width: 2em;
		height: 2em;
		color: @black;
		border: 1px solid @gray;
		border-radius: 4px;
		appearance: none;
		outline: 0;
		cursor: pointer;
		transition: background 225ms cubic-bezier(0.1, 0.1, 0.25, 1);

		&::before {
			position: absolute;
			content: '';
			display: block;
			top: 2px;
			left: 7px;
			width: 8px;
			height: 14px;
			border-style: solid;
			border-color: @white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			opacity: 0;
		}


		&:checked {
			color: @white;
			border-color: @green;
			background: @green;

			&::before {
				opacity: 1;
			}

			~label::before {
				clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
			}
		}

		&:disabled {
			filter: brightness(25%); //background: @gray;
		}

	}
}

.radio.large {
	font-size: 2em;
	line-height: 1.4em;

	input[type='radio'] {
		/* remove standard background appearance */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		/* create custom radiobutton appearance */
		display: inline-block;
		width: 2em;
		height: 2em;
		padding: 4px;
		/* background-color only for content */
		background-clip: content-box;
		border: 2px solid #bbbbbb;
		background-color: #e7e6e7;
		border-radius: 50%;
	}

	/* appearance for checked radiobutton */
	input[type='radio']:checked {
		background-color: #06842c;
		//		background-color: #93e026;
	}
}
</style>
